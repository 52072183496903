import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import bg from '../assets/bg2.jpg';

export default createGlobalStyle`
#carregando{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
  }

  .loading-indicator {
    background: rgba(0,0,0,0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;

    img {
      position: relative;
      left: 50%;
      top: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }

  .fullbg {
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .caption {
    font-size: 30pt;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .caption:after {
    content:"\A";
    width:10px;
    height:10px;
    border-radius:50%;
    background: #00bbeb;
    display: inline-block;
    margin: 0 0 -2px;
  }

  .react-multiple-carousel__arrow--right{
    right: 2px!important;
  }

  .react-multiple-carousel__arrow--left{
    left: 2px!important;
  }

  .contato {
    background-color: #DCDCDC;
  }

  .servicos {
    background-color: #7A8A9E!important;
  }
`;
