import React from 'react';

function Footer() {
  return (
    <footer className="pt-5 pb-4 mt-md-5 pt-md-5 border-top">
      <div className="container">
        <div className="row py-5">
          <div className="col-12 col-md">
            <h4>Edifício Comercial</h4>
            <p>Serviço responsável por divulgar empresas e serviços em edifícios comerciais.</p>
            <div className="redes-sociais">
              <a href="#" title="Facebook" target="_blank" className="facebook mr-2 d-inline-block border rounded-cicled-inline-block  border  rounded-circle"><i className="flaticon flaticon-facebook"></i></a>
              <a href="#"  title ="Twitter" target="_blank" className="twitter mr-2 d-inline-block border rounded-cicled-inline-block  border  rounded-circle"><i className="flaticon flaticon-twitter"></i></a>                
              <a href="#" title ="Google mais" target="_blank" className="google-plus mr-2 d-inline-block border rounded-cicled-inline-block  border  rounded-circle"><i className="flaticon flaticon-google-plus"></i></a> 
            </div>
          </div>
          <div className="col-6 col-md">
            <h5>Ed. Comerciais</h5>
            <ul className="list-unstyled text-small">
              <li><a className="text-muted" href="#">Sobre</a></li>
              <li><a className="text-muted" href="#">Como Divulgar</a></li>
              <li><a className="text-muted" href="#">Edifícios Comerciais</a></li>
              <li><a className="text-muted" href="#">Contato</a></li>            
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>Parque Office</h5>
            <ul className="list-unstyled text-small">
              <li><a className="text-muted" href="#">Sobre</a></li>
              <li><a className="text-muted" href="#">Buscar Serviço</a></li>
              <li><a className="text-muted" href="#">Contato</a></li>            
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>Contato</h5>
            <ul className="list-unstyled text-small">
              <li><a className="text-muted" href="#"><i className="flaticon-envelope"></i> contato@edificilcomercial.com</a></li>
              <li><a className="text-muted" href="#"><i className="flaticon-telephone"></i> (91) 98253-9091</a></li>
              <li><a className="text-muted" href="#"><i className="flaticon-pin"></i> Ed. Parque Office, 4300</a></li>                        
            </ul>
          </div>        
        </div>
        <div className="row copyright">
          <div className="col">Copyright &copy;2020 All rights reserved | Desenvolvido por Jeta Tecnologia</div>
          <div className="col-auto">www.edificilcomercial.com</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;