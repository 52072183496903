function isMobile() {
  console.log(navigator.userAgent);
  var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.search(/(android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i) !== -1)
    return true;
}

export default {
  versao: '0.1.5',
  nome: 'Edifícil Comercial',
  isMobile: isMobile(),
  urlLogo: 'https://diariooficial.netlify.app/logos/',
  // baseURL: "http://192.168.0.5:3333/",
  baseURL: "https://maisescolaweb.com.br/edificiocomercial-api/",
};
