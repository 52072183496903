import axios from "axios";
import { getToken } from "./auth";
import config from './config';

const api = axios.create({
  baseURL: config.baseURL
  // baseURL: "http://192.168.0.8:3333/"
  // baseURL: "http://www.aplicativomaisescola.com.br/api/v2/"
  // baseURL: "http://192.168.0.112:3333/"
});

api.interceptors.request.use(function (config) {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  var loading = document.getElementById('loadingIncicator');
  loading.classList.add('loading-indicator');
  loading.style.display = 'block';

  return config
}, function (error) {
  return Promise.reject(error);
});

api.interceptors.response.use(function (response) {

  // spinning hide
  // UPDATE: Add this code to hide global loading indicator
  var loading = document.getElementById('loadingIncicator');
  loading.classList.remove('loading-indicator');
  loading.style.display = 'none';


  return response;
}, function (error) {
  var loading = document.getElementById('loadingIncicator');
  loading.classList.remove('loading-indicator');
  loading.style.display = 'none';
  return Promise.reject(error);
});

export default api;
