import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../services/api';

function Header() {
  const { edificio } = useParams();
  const [ed, setEd] = useState(null);

  useEffect(() => {
    async function load(){
      const edificio_ = await api.get('/edificio', { params: { url: edificio }});
      console.log(edificio_);
      setEd(edificio_.data);
      document.title = edificio_.data.nome;
    }
    load();

  }, []);

  return (
    <header>
      <div className="container" id="topo">
        <nav className="navbar navbar-expand-lg navbar-light">
          {ed && (
            <a className="navbar-brand text-uppercase" href={'/'+ed.url}>{ed.nome}</a>
          )}
          {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#menu-principal" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> 
          <div className="collapse navbar-collapse" id="menu-principal">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a className="nav-link" href="/parqueoffice">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/parqueoffice">Buscar serviço</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">Sobre</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" tabIndex="-1">Contato</a>
              </li>
            </ul> 
          </div>
          */}
        </nav>   
      </div>           
    </header>
  );
}

export default Header;