import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import config from '../../services/config';
import api from '../../services/api';
import semImagem from '../../assets/sem_imagem.jpg';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Link, useParams } from 'react-router-dom';

function Resultado({dados, edificio}) {
  
  return (
    <section className="destaques">
      <div className="container">
        <h2 className="d-block text-center mb-4">Resultado da Busca <br /><span style={{fontSize: 20,}}>Empresas encontradas</span></h2>
        <div className="card-deck mb-3">
          {dados.map((emp, index) => 
            <div key={index} className="card mb-4 col-sm-4 col-md-4 overflow-hidden rounded" style={{paddingLeft: 0, paddingRight: 0}}>
              <div className="card-header p-0 overflow-hidden">
              <Link to={edificio + '/' + emp.url}>
                <img src={emp.arquivo ? emp.arquivo : "https://dummyimage.com/620x380/cccccc/ffffff.jpg"}  />
              </Link>
              </div>
              <div className="card-body">
                <h3 className="card-title pricing-card-title"><Link to={edificio + '/' + emp.url}>{emp.nome}</Link></h3>
                <ul className="list-unstyled list-inline mt-3 mb-4 rating">
                  <li className="list-inline-item marked"></li>
                  <li className="list-inline-item marked"></li>
                  <li className="list-inline-item marked"></li>
                  <li className="list-inline-item marked"></li>
                  <li className="list-inline-item marked"></li>
                  <li className="list-inline-item marked"></li>
                  {/* <li className="list-inline-item"> 20 Reviews</li> */}
                </ul>
                {(emp.categorias) && emp.categorias.split(',').map((tag, index) => 
                  tag.length > 1 && (
                    <span key={index} className="badge badge-secondary py-2 px-3 mt-1 fw-light badge-pill">
                          {tag}
                        </span>
                  )
                )}
              </div>
            </div>
          )}
        </div> 
      </div>            
    </section>
  );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(Resultado);