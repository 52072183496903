import React, { useEffect, useState  } from 'react';
import { connect } from 'react-redux';
import api from '../../services/api';
import config from '../../services/config';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Link, useParams, useHistory } from 'react-router-dom';
import Resultado from '../Resultado';
import { useAlert } from 'react-alert';

function Home({usuario, history}) {
  const alert = useAlert()

  const [ed, setEd] = useState(null);
  const [empresas, setEmpresas] = useState([]);
  const [empresasProcuradas, setEmpresasProcuradas] = useState([]);
  const [resultado, setResultado] = useState(null);
  const [texto, setTexto] = useState('');
  
  let {edificio} = useParams();
  
  useEffect(() => {

    async function load(){
      const empresas_ = await api.get('/empresas/destaques', { params: { url: edificio }});
      const emp = await api.get('/empresas/procurados', { params: { url: edificio }});
      const edificio_ = await api.get('/edificio', { params: { url: edificio }});

      if (!edificio_.data){
        history.replace('/404');
      }

      setEmpresasProcuradas(emp.data);
      setEmpresas(empresas_.data);
      setEd(edificio_.data);
    }

    load();
  }, []);

  async function pesquisar(e){
    e.preventDefault();

    if (texto.length < 1){
      alert.error('Você precisa digitar uma palavra que gostaria de pesquisar.');
      setResultado(null);
    }else{
      const ret = await api.get('/empresas/consulta', {params: { texto, url: edificio }});
      setResultado(ret.data);
    }
  }

  function voltar(e){
    e.preventDefault();
    setResultado(null);
  }

  return (
    <>
    <Header />
    <main role="main">
        <div className="fullBackground" style={{
           background: `url(${ed ? ed.imagem_topo : 'https://unsplash.it/1600/480/?random'})`,
           backgroundSize: 'cover',
        }}>
          <section className="jumbotron overlay rounded-0">
            <div className="container">
              <h1><span className="d-block"  style={ config.isMobile ? {fontSize: 40} : {}}>{ed && ed.titulo_banner}</span> </h1>
              <p className="lead text-muted mb-5">{ed && ed.descricao_banner}</p>
              <form >            
                <div className="form-row align-items-center pt-5 pb-3 search">
                  <div className="col">
                    <input onChange={e => setTexto(e.target.value)} type="text" className="form-control form-control-lg mb-2 rounded-0" placeholder="Digite o nome de uma empresa ou serviço" />                    
                  </div>                           
                  <div className="col-auto">
                    <button onClick={pesquisar} className="btn btn-primary btn-lg mb-2">Pesquisar</button>
                  </div>
                </div>
              </form>          
            </div>
          </section>  
        </div>  
        {resultado ? (
          resultado.length > 0 ? (
            <Resultado dados={resultado} edificio={edificio}/>
          ) : (
            <div className="text-center p-5">
              Não encontramos nenhum serviço ou empresa com a palavra escolhida.
            </div>
          )
        ) : (
          <>
        <section className="destaques">
          <div className="container">
            <h2 className="d-block text-center mb-4 titulo">Destaques</h2>
            <div className="card-deck mb-3">
              {empresas.map((emp, index) => 
                <div key={index} className="card mb-4 overflow-hidden rounded">
                  <div className="card-header p-0 overflow-hidden">
                    <Link to={'/' + edificio + '/' + emp.url}>
                      <img src={emp.arquivo ? emp.arquivo : "https://dummyimage.com/620x380/cccccc/ffffff.jpg"}  />
                    </Link>
                  </div>
                  <div className="card-body">
                    <h3 className="card-title pricing-card-title"><Link to={'/' + edificio + '/' + emp.url}>{emp.nome}</Link></h3>
                    <ul className="list-unstyled list-inline mt-3 mb-4 rating">
                      <li className="list-inline-item marked"></li>
                      <li className="list-inline-item marked"></li>
                      <li className="list-inline-item marked"></li>
                      <li className="list-inline-item marked"></li>
                      <li className="list-inline-item marked"></li>
                      <li className="list-inline-item marked"></li>
                      {/* <li className="list-inline-item "> 20 Reviews</li> */}
                    </ul>
                    {(emp.categorias) && emp.categorias.split(',').map((tag, index) => 
                      tag.length > 1 && (
                        <span key={index} className="badge badge-secondary py-2 px-3 mt-1 fw-light badge-pill">
                          {tag}
                        </span>
                      )
                    )}
                  </div>
                </div>
              )}
              
            </div> 
           
            {/* <h3 className="d-block mb-4 subtitulo text-uppercase"><span className="bg-white pr-3">Mais Procurados</span></h3>
            <div className="card-deck mb-3">
              {empresasProcuradas.map((emp, index) => 
                  <div key={index} className="card mb-4 overflow-hidden rounded">
                    <div className="card-header p-0 overflow-hidden">
                      <img src={emp.arquivo ? emp.arquivo : "https://dummyimage.com/620x380/cccccc/ffffff.jpg"}  />
                    </div>
                    <div className="card-body">
                      <h3 className="card-title pricing-card-title"><Link to={edificio + '/' + emp.url}>{emp.nome}</Link></h3>
                      <ul className="list-unstyled list-inline mt-3 mb-4 rating">
                        <li className="list-inline-item marked"></li>
                        <li className="list-inline-item marked"></li>
                        <li className="list-inline-item marked"></li>
                        <li className="list-inline-item marked"></li>
                        <li className="list-inline-item marked"></li>
                        <li className="list-inline-item "> 20 Reviews</li> 
                      </ul>
                      {(emp.categorias) && emp.categorias.split(',').map((tag, index) => 
                        tag.length > 1 && (
                          <span key={index} className="badge badge-secondary py-2 px-3 mt-1 fw-light badge-pill">
                            {tag}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                )}  
            </div>       
           */}
          </div>
        </section>
        <section className="sobre py-4">
          <div className="container">
            {ed && (
              <div className="row">              
                <div className="col-12 col-md"> 
                    <h3 className="text-uppercase col-12 mb-4 pl-0"><span className="d-block">Sobre Edifício Comercial</span>{ed.nome}</h3>                 
                    <p>
                    {ed.descricao}
                    </p>
                </div>
                <div className="col-12 col-md">
                  <div className="row">
                    <div className="col-6 pr-2">
                      <img src={ed.imagem01 ? ed.imagem01 : "https://unsplash.it/681/480/?random"} className="img-fluid" />
                    </div>               
                    <div className="col-6 pl-2">
                      <img src={ed.imagem02 ? ed.imagem02 : "https://unsplash.it/681/480/?random"} className="img-fluid"/>
                    </div>
                    <div className="w-100 my-2"></div>
                    <div className="col-12">
                      <img src={ed.imagem03 ? ed.imagem03 : "https://unsplash.it/681/480/?random"} className="img-fluid"/>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        </>
        )}
      </main>
      <Footer />
    </>
  );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(Home);