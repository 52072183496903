import React, {useState, useEffect} from 'react';

import { Container } from './styles';

function Page404() {
    return (
        <Container>
            <div className="error-wrapper">
                <div className="man-icon"></div>
                <h3 className="title">404</h3>
                <p className="info">Oh! Pagina não encontrada</p>                
            </div>
        </Container>
    );
}

export default Page404;